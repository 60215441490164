<template>
  <div class="mb-2">

      <!-- <VCol cols="auto" class="text-right">
       <VBtn
         depressed
         class="success-bg text-white text-transform-none br-10"
         @click="createDialog = true"
       >
         <VIcon left>mdi-plus</VIcon>
         Додати опис
       </VBtn>
      </VCol> -->
      <VCard class="w-100 " elevation="2">
        <VCardTitle>
          <VRow class="align-center" no-gutters justify="center"  justify-xl="space-between" justify-lg="space-between" justify-sm="center" justify-md="space-between" :class="{'flex-column-reverse ' : $vuetify.breakpoint.smAndDown}">
            <VCol cols="12" lg="3" md="5" sm="12" >
              <VTextField
                dense
                prepend-inner-icon="mdi-magnify"
                :placeholder="$t('form.search')"
                v-model="searchString"
                clearable
                hide-details
                solo
                :loader-height="3"
                @input="onSearch"
                :loading="isSearchLoading"
            />
            </VCol>
            <VCol cols="auto" class="text-right">
             {{$t('catalog.catalogTitle')}}
          </VCol>
        </VRow>
        </VCardTitle>
        <VDivider  class="mt-1"/>
      <chip-tab :items="chipTabsItems" @onClickChipTab="onClickChipTab"
          :loadingChips="loadingChips || Boolean(searchString)"
          :resetChip="Boolean(searchString)|| isResetChip"/>
      </VCard>
       <VSlideXTransition mode="out-in" :hide-on-leave="true">
    <VRow  no-gutters justify="start" class="mr-0 pb-3"  v-if="!isSearchLoading && descriptions.length">
      <VCol
        cols="12"
        md="3"
        sm="6"
        v-for="item in descriptions"
        :key="item.uuid"
        class="py-0 my-0 "
      >
       <VCol class="mx-0 px-2 pb-0">
            <DescriptionItem :item="item" @delete="onDeleteDescription(item.uuid)" @toggle-row="activeCard" :activeCardID="activeCardID"/>
        </VCol>
      </VCol>
    </VRow>
       </VSlideXTransition>
           <VFadeTransition mode="out-in" :hide-on-leave="true" >
        <div v-if="isSearchLoading">
          <div class="search-loader__wrapper">
            <div class="search-loader__inner">
              <v-progress-circular
              indeterminate
              color="primary"
              >
              </v-progress-circular>
            </div>
          </div>
        </div>
    </VFadeTransition>
        <div v-if="!isSearchLoading && !descriptions.length" class="w-100 d-flex align-center justify-content-center" style="height:20vh">
      {{$t('table.noData')}}
    </div>
      <div v-observer="{nextPage:nextPage}" class="observer" v-if="showObserver || !isSearchLoading"></div>
      <div v-if="isLoadingFilter && !isSearchLoading" class="w-100 text-center">
        <v-progress-circular
          indeterminate
          color="primary"

        ></v-progress-circular>
    </div>


    <AddDescriptionDialog
      :visible="createDialog"
      @close="createDialog = false"
    />
  </div>
</template>

<script>
import _ from "lodash";
import DescriptionItem from './DescriptionItem.vue';
import depotService from '../../../services/request/depot/depotService';
import notifications from '../../../mixins/notifications';
import loader from '../../../mixins/loader';
import AddDescriptionDialog from './AddDescriptionDialog.vue';
import EventBus from '../../../events/EventBus';
import chipTab from '@/components/common/chipTab.vue'

export default {
  name: 'DescriptionsComponent',
  components: {
    AddDescriptionDialog,
    DescriptionItem,
    chipTab
  },
  mixins: [loader, notifications],
  data: () => ({
    descriptions: [],
    createDialog: false,
    page: 0,
    perPage:15,
    totalPages:0,
    isLoadingFilter:false,
    showObserver:false,
    activeCardID:'',
    searchString:'',
    oldSearchString:'',
    isSearchLoading:false,
    chipTabsItems:{},
    loadingChips:false,
    categoryChipTab:'',
    isResetChip:false,
  }),
  async mounted() {
    this.getChipTabsItems()
    EventBus.$on('description-added', async () => {
      await this.getDescriptions();
    });
    EventBus.$on('description-updated', async (item) => {
      const newItem = this.descriptions.find(e => e.uuid === item.uuid)

      if(newItem.name !== item.name){
        if(this.searchString){
          this.onSearch()
        }
        if(this.categoryChipTab !== this.$t('btn.all')){
          this.onClickChipTab(this.categoryChipTab)
        }
      }
        const itemIndex = this.descriptions.indexOf(this.descriptions.find(e => e.uuid === item.uuid))
        this.descriptions.splice(itemIndex,1,item)
      // await this.getDescriptions(false,false,true);
    });
    await this.getDescriptions();
    this.showObserver = true
  },

  methods: {
    async onClickChipTab(e) {
      this.categoryChipTab = e
      this.loadingChips = true

      if (e === this.$t('btn.all')) {
        if (this.searchString) {
          this.searchString = ''
        }
        await this.getDescriptions();
      } else {
        if (this.searchString) {
          this.searchString = ''
        }
         try{
            this.page = 0
            this.isSearchLoading = true
            const params = {
              name:e,
              uselike:true,
              offset:this.page * this.perPage,
              limit:this.perPage,
              order:'DESC',
              orderby:'time_created'
            }
          const newItems = await depotService.getProductsDescriptions(params);
          this.descriptions = newItems;
          setTimeout(()=>{
            this.isSearchLoading = false
          },500)
         }catch(e){
           this.isSearchLoading = false
           this.setErrorNotification(e)
         }


      }
      this.loadingChips = false
    },

    async getChipTabsItems() {
      try {
        const params = {}
        const chipItems = await depotService.getItemsChipListCatalog(params)
        this.chipTabsItems = chipItems
        if (this.chipTabsItems) {
          this.chipTabsItems = this.chipTabsItems.sort((a, b) => b.count - a.count)
        }
      } catch (e) {
        this.setErrorNotification(e)
      }
    },

    onSearch: _.debounce(async function(){
        if(this.searchString){
         try{
            this.page = 0
            this.isSearchLoading = true
            const params = {
              name:this.searchString,
              uselike:true,
              offset:this.page * this.perPage,
              limit:this.perPage,
              order:'DESC',
              orderby:'time_created'
            }
          const newItems = await depotService.getProductsDescriptions(params);
          this.descriptions = newItems;
          setTimeout(()=>{
            this.isSearchLoading = false
          },500)
            this.oldSearchString = this.searchString
         }catch(e){
           
           this.isSearchLoading = false
           this.setErrorNotification(e)
         }

        }else{
          this.categoryChipTab = this.$t('btn.all')
          this.page = 0
          await this.getDescriptions();
        }
    },100),
    activeCard(e){
      this.activeCardID = e
    },
      async nextPage() {
        this.page += 1;
        await this.getDescriptions(true,true);
    },
    async onDeleteDescription(uuid) {
      try {
        this.setLoading();
        await depotService.deleteProductDescription(uuid);
        this.setLoading(false);
        this.setSuccessNotification(this.$t('catalog.item_desc_deleted_successfully'));
        await this.getDescriptions();
      } catch (e) {
        this.setLoading(false);
        this.setErrorNotification(e);
      }
    },
    async getDescriptions(next = false,isLoadingLine,update = false ) {
      try {
        if(isLoadingLine){
          this.isLoadingFilter = true
        }else{
          this.setLoading(true);
        }
        const params = {
              order:'DESC',
              orderby:'time_created',
        }
        if(this.searchString){
          params.name = this.searchString
          params.uselike = true
        }
        if(this.categoryChipTab && this.categoryChipTab !== this.$t('btn.all')){
          params.name = this.categoryChipTab
          params.uselike = true
        }
        params.offset = update ? 0 : this.page * this.perPage
        params.limit = update ? this.largeLimit  : this.perPage

        
        const newItems = await depotService.getProductsDescriptions(params);
        // console.log(params)
        if (next) {
          this.descriptions.push(...newItems);
        } else {
          this.descriptions = newItems;
        }
        this.totalPages = this.descriptions.length / this.perPage
        this.showObserver = this.page <= Math.ceil(this.totalPages)
        // console.log(Math.ceil(this.totalPages))
        // console.log(this.page)
        if(isLoadingLine){
          this.isLoadingFilter = false
        }else{
          this.setLoading(false);
        }
      } catch (e) {
        if(isLoadingLine){
      this.isLoadingFilter = false
      }else{
        this.setLoading(false);
      }
        this.setErrorNotification(e);
      }
    },
  },
  computed:{
    largeLimit(){
      const pageLimit = this.perPage * (this.page)
      if(pageLimit >= 1000){
         this.page = (this.pageBeforeLimit - 1)
         this.$scrollTop()
          return 1000
      }
      return this.perPage * (this.page)
    }
  },
  watch:{
    page(e){
      if(this.perPage * (this.page) >= 1000 && this.perPage * (this.page) <=  1000 + this.perPage) {
        this.pageBeforeLimit = e
      }
    },
  }
};
</script>

<style scoped lang="scss">
.search{
  &-loader__wrapper{
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 20vh;
  }
  &-loader__inner{
    position: absolute;

  }

}
</style>
