import { render, staticRenderFns } from "./DescriptionsComponent.vue?vue&type=template&id=39f43994&scoped=true&"
import script from "./DescriptionsComponent.vue?vue&type=script&lang=js&"
export * from "./DescriptionsComponent.vue?vue&type=script&lang=js&"
import style0 from "./DescriptionsComponent.vue?vue&type=style&index=0&id=39f43994&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "39f43994",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VFadeTransition } from 'vuetify/lib/components/transitions';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSlideXTransition } from 'vuetify/lib/components/transitions';
import { VTextField } from 'vuetify/lib/components/VTextField';
installComponents(component, {VCard,VCardTitle,VCol,VDivider,VFadeTransition,VProgressCircular,VRow,VSlideXTransition,VTextField})
